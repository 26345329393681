import React, { Component} from 'react';

import * as ROLES from '../../constants/roles';
import { withFirebase } from '../../services/Firebase';


class AdminButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isAdmin: props.user.roles.includes(ROLES.ADMIN)
    };

  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleEvent = () => {
    this.setState({loading: true});
    let newRoles = this.state.isAdmin
                ? this.props.user.roles.filter(role => role !== ROLES.ADMIN)
                : [...this.props.user.roles, ROLES.ADMIN];

    this.props.firebase.user(this.props.user.uid).update({roles: newRoles})
      .then(() => {
        this.props.user.roles = newRoles;
        this.setState({isAdmin: newRoles.includes(ROLES.ADMIN), loading: false});
      });

  };

  render() {

    return (
      <span>
        <button onClick={this.handleEvent} disabled={this.state.loading}
          >{this.state.isAdmin ? "Disable" : "Enable"}</button>
      </span>
    );

  }
}

export default withFirebase(AdminButton);

