import React, {Component} from 'react';
import { compose } from 'recompose';

import {withFirebase} from '../../services/Firebase';
import {withAuthorization} from "../../services/Session";
import Server from '../../services/Server';

import * as ROLES from "../../constants/roles";

import ActionForm, {INITIAL_ACTION} from "./ActionForm";
import AuthForm from "./AuthForm";


class Actions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      actions: [],
      aid: null,
      auth: [],
      action: [],
      message: null,
      results: null
    };

    this.loadAction = this.loadAction.bind(this);
    this.execute = this.execute.bind(this); //execute action
  }

  componentDidMount() {
    this.setState({loading: true});

    this.props.firebase.actions().on('value', snapshot => {
      const actionsObject = snapshot.val() || [];

      const actionsList = Object.keys(actionsObject).map(key => ({
        ...actionsObject[key],
        aid: key
      }));

      this.setState({
        actions: actionsList.sort((a,b) => a.name.localeCompare(b.name)),
        loading: false,
        result: null
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.actions().off();
  }

  loadAction(event) {

    const aid = event.target.value;
    const action = aid === "-NEW-"
             ? {aid: null}
             : this.state.actions.find(a => a.aid === aid);

    this.setState({
      action: {...INITIAL_ACTION(), ...action}
    });
    this.messenger(aid === "-NEW-" ? "New action" : "Loaded action id=" + aid );
    event.target.value = null;
  }

  messenger = (message)  => {
    this.setState({message: message});
    if (!message) {
      this.setState({results: null});
    }
  };

  execute() {
    //get the auth & action
    //issue command *& report result
    const server = new Server(this.state.auth);
    server.perform(this.state.action)
      .then(reply => {
        this.setState({results: reply});
        this.messenger("Action executed.")
      })
      .catch(err => {
        this.setState({results: "ERROR on call: " + err});
        this.messenger("Action execution error.")
      });
  }

  render() {
    const {actions, loading, results} = this.state;

    return (
      <div>
        <h1>Actions</h1>
        <h2>Matrix actions - create and take actions</h2>
        <p
          className={"message-area"}
          style={{
            minHeight: "20px",
            padding: "7px",
            border: this.state.message? "1px solid black" : "transparent"}}>
          {this.state.message && this.state.message}
        </p>
        {loading && <div>Loading ...</div>}
        {!loading &&
          <div>
            <p>
              <button
                type="button"
                onClick={this.execute}
              >Execute
              </button>
            </p>
            <hr/>
            <AuthForm auth={this.state.auth} messenger={this.messenger}/>
            <hr/>
            {actions.length >0  &&
            <div
              style={{width: "300px", paddingBottom:"5px", marginBottom:"10px", borderBottom: "1px gray dotted"}}
              >
              Load an Action:
              <select
                className={"field-select"}
                value=""
                onChange={this.loadAction}
              >
                <option value="" disabled="disabled"></option>
                <option value="-NEW-">--NEW--</option>
                {actions.map(action => (
                  <option key={action.aid} value={action.aid}>{action.name}</option>
                ))}
              </select>
            </div>
            }
            <ActionForm action={this.state.action} messenger={this.messenger}/>
            {results &&
              <div>
                <hr/>
                <h2>Results</h2>
                <pre>{JSON.stringify(results, undefined, 2)}</pre>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}

const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Actions);

