import React, { Component} from 'react';

import { withFirebase } from '../../services/Firebase';
import {compose} from "recompose";
import {AuthUserContext} from "../../services/Session";


class DeleteButton extends Component {
  /*
  constructor(props) {
    super(props);
  }
     */

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleEvent = (event) => {
    event.preventDefault();
    this.props.firebase.user(this.props.user.uid).remove();
  };

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser =>
          authUser && authUser.uid !== this.props.user.uid
          ? (
            <button
              type="button"
              className="link-button"
              onClick={this.handleEvent}>
              Delete User
            </button>
          )
          : (
          <span>&nbsp;</span>
          )
        }
      </AuthUserContext.Consumer>

    );

  }
}


export default compose(
  withFirebase
)(DeleteButton);