import React, { Component} from 'react';

import { withFirebase } from '../../services/Firebase';

const INITIAL_AUTH = {
  pid: null,
  url: null, //use current value
  user: '',
  password: '',
};

class AuthForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      auths: [],
      loading: false
    };

    if (!(props.auth.hasOwnProperty('pid'))) {
      Object.assign(props.auth, INITIAL_AUTH);
    }

    this.selectProject = this.selectProject.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({loading:true});

    this.props.firebase.projects().on('value', snapshot => {
      const projectsObject = snapshot.val() || [];

      const projectsList = Object.keys(projectsObject).map(key => ({
        ...projectsObject[key],
        pid: key
      }));

      this.setState({
        projects: projectsList,
        loading: false
      });
      if (this.props.auth.pid === null || !projectsObject.hasOwnProperty(this.props.auth.pid)) {
        this.setProject(projectsList[0].pid, projectsList[0].url);
      }
    });
  }

  componentWillUnmount() {
    this.props.firebase.projects().off();
  }

  onChange = event => {

    const value = event.target.value;
    const name = event.target.name;
    this.props.auth[name] = value;
    const pid = this.props.auth.pid;
    if (pid === null){ //on render
      return;
    }
    const auth = this.state.auths[pid];
    auth[name] = value;
    this.props.messenger();
  };

  setProject(pid, url) {
    if (!this.state.auths[pid]) {
      const auths = this.state.auths;
      auths[pid] = {
        //carry over whatever we have
        user: this.props.auth.user,
        password: this.props.auth.password,
        //and set url & pid
        pid: pid,
        url: url

      };
    }
    //then promote up for use in execute
    Object.assign(this.props.auth, this.state.auths[pid]);
  }

  selectProject(event) {
    const pid = event.target.value;
    const project = this.state.projects.find(s => s.pid === pid);
    this.setProject(pid, project.url);
    this.props.messenger("Project set to " + project.name);
  }

  render() {
    const { loading, projects} = this.state;
    const {user, password, project} = this.props.auth;

    return (
      <div>
        {loading && <div>Loading ...</div>}
        {!loading &&
        <div>
          <label>
            <strong>Select Project:</strong>
            <select value={project} onChange={this.selectProject}>
              {projects.map(p => (
                <option key={p.pid} value={p.pid}>{p.name}</option>
              ))}
            </select>
          </label>
          <div
            style={{marginTop:"7px", marginLeft:"4px"}}><strong>Credentials</strong></div>
          <div className="rTable">
            <div className="rTableRow">
              <div className="rTableCell"><strong>User</strong></div>
              <div className="rTableCell">
                <input
                  name="user"
                  value={user}
                  onChange={this.onChange}
                  type="text"
                  style={{width:"200px"}}
                  placeholder="User"
                />
              </div>
            </div>
            <div className="rTableRow">
              <div className="rTableCell"><strong>Password</strong></div>
              <div className="rTableCell">
                <input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default withFirebase(AuthForm);

