import React, { Component} from 'react';

import { withFirebase } from '../../services/Firebase';
import {REQUEST_METHOD} from "../../services/Server/Server";

const INITIAL_ACTION = () => {
  return {
    name: '',
    description: '',
    path: '',
    method: REQUEST_METHOD[0],  //first is default
    variables: [], //substituted into place in parameter values and path
    parameters: []
  };
};

class ActionForm extends Component {

  constructor(props) {
    super(props);

    if (!props.action.aid) {
      //fill in the rest
      Object.assign(props.action, INITIAL_ACTION());
    }

    this.state = {
      tainted: false,
      newParamName: '',
      newParamValue: '',
      newVarName: '',
      newVarValue: '',
      newVarDescription: '',
      showingDetails: true
    };

    //bindings
    this.save = this.save.bind(this);  //save new action
    this.update = this.update.bind(this);  //update existing action
    this.delete = this.delete.bind(this); //delete action (only available !tainted

    this.deleteVariable = this.deleteVariable.bind(this);  //eliminate a variable
    this.addVariable = this.addVariable.bind(this);  //add a new variable

    this.deleteParam = this.deleteParam.bind(this);  //eliminate a parameter
    this.addParam = this.addParam.bind(this);  //add a new parameter

    this.toggleDetails = this.toggleDetails.bind(this);  //add a new parameter
  }

  componentDidMount() {
    //nothing to do
  }

  componentWillUnmount() {
  }

  onChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    if (name.startsWith("param")) {
      const type = name.substring(name.indexOf("-") + 1);
      const inx = parseInt(name.substring(5));
      this.props.action.parameters[inx][type] = value;
    } else if (name.startsWith("var")) {
      const type = name.substring(name.indexOf("-") + 1);
      const inx = parseInt(name.substring(3));
      this.props.action.variables[inx][type] = value;
    } else if (name.startsWith("newParam") || name.startsWith("newVar")) {
      this.setState({[name]: value});
    } else {
      this.props.action[name] = value;
    }
    if (name === "path" || name.search(/^param.*-value/) !== -1) {
      this.updateVariablesUsed();
    }
    this.setState({tainted: true});
    this.props.messenger();
  };

  deleteParam(event) {
    const name = event.target.name;
    const inx = parseInt(name.substring(5));
    const pName = this.props.action.parameters[inx].name;
    this.props.action.parameters.splice(inx, 1);
    this.updateVariablesUsed();
    //force update?
    this.setState({tainted: true});
    this.props.messenger(`Parameter ${pName} deleted`);
  }

  addParam() {
    this.props.action.parameters.push({
      name : this.state.newParamName,
      value : this.state.newParamValue
    });
    this.updateVariablesUsed();
    this.setState({
      tainted: true,
      newParamName: '',
      newParamValue: ''
    });
    this.props.messenger(`Parameter ${this.state.newParamName} added`);
  }

  deleteVariable(event) {
    const name = event.target.name;
    const inx = parseInt(name.substring(3));
    const vName = this.props.action.variables[inx].name;
    this.props.action.variables.splice(inx, 1);
    //force update?
    this.setState({tainted: true});
    this.props.messenger(`Variable ${vName} deleted`);
  }

  addVariable() {
    this.props.action.variables.push({
      name : this.state.newVarName,
      value : this.state.newVarValue,
      description: this.state.newVarDescription
    });
    this.setState({
      tainted: true,
      newVarName: '',
      newVarValue: '',
      newVarDescription: ''
    });
  }

  toggleDetails() {
    this.setState({showingDetails: !this.state.showingDetails});
  }

  updateVariablesUsed() {
    let varsUsed = [];
    const re = /\${([^}]+)}/g;
    let values = [...this.props.action.parameters];
    values.push(this.props.action.path);
    for(let i = 0; i < values.length; i++) {
      const name = typeof values[i] === 'string' ? "path" : "Parameter " + values[i].name;
      const value = typeof values[i] === 'string' ? values[i] : values[i].value;
      let m = [];
      do {
        m = re.exec(value);
        if (m) {
          varsUsed[m[1]] = name;
        }
      } while (m);
    }
    //now make sure inUse properly set
    for(let i = 0; i < this.props.action.variables.length; i++) {
      const v = this.props.action.variables[i];
      v.inUse = varsUsed.hasOwnProperty(v.name);
      delete varsUsed[v.name];
    }

    //whatever we still have we must create
    for (let name in varsUsed) {
      if (varsUsed.hasOwnProperty(name)) {
        this.props.action.variables.push({
          name: name,
          value: '',
          description: `Used in ${varsUsed[name]}`,
          inUse: true
        });
      }
    }
  }

  save() {
    this.props.messenger();
    const action = {...this.props.action};
    delete action.aid; //no aid on new
    this.props.action.aid = this.props.firebase.actions().push(action).key;
    this.setState({ tainted: false});
    this.props.messenger("New Action Saved.");
  };

  update() {
    this.props.messenger();
    const action = {...this.props.action};
    delete action.aid; //no aid contained
    this.props.firebase.action(this.props.action.aid).update(action)
      .then(() => {
        this.setState({tainted: false});
        this.props.messenger("Action Updated.");
      });
  };

  delete(event) {
    this.props.firebase.action(this.props.action.aid).remove()
      .then(() => {
        //reset Action to New
        Object.assign(this.props.action, INITIAL_ACTION());
        this.props.action.aid = null;
        this.setState({tainted: false});
        this.props.messenger("Action Deleted.");
      });

    event.preventDefault();
  }

  render() {
    //make sure everything reset here
    const {aid, name, description, variables, path, method, parameters} = this.props.action;
    const {newParamName, newParamValue, newVarName, newVarValue, newVarDescription, showingDetails} = this.state;

    const isInvalid = name === '' || description === '';

    return (
      <div>
        {aid
         ?
         <div
           style={{marginTop: "6px"}}
            >
           <strong>{`Action id:${aid}`}</strong>
           <button
             type="button"
             disabled={isInvalid || !this.state.tainted}
             onClick={this.update}
           >
             Update
           </button>
           <button
             type="button"
             className="link-button"
             style={{paddingLeft: "12px"}}
             onClick={this.delete}>
             Delete Action
           </button>
         </div>
         :
         <div>
           <strong>New Action</strong>
           <button
             type="button"
             disabled={isInvalid}
             onClick={this.save}
           >
             Save
           </button>
         </div>
        }


        <div>
          <span style={{width:"150px", display: "inline-block"}}><strong>Name</strong></span>
          <input
            name="name"
            value={name}
            onChange={this.onChange}
            type="text"
            style={{width:"300px"}}
            placeholder="Action Name"
          />
          {aid &&
          <button
            type="button"
            disabled={isInvalid}
            onClick={this.save}
          >
            Save as New
          </button>
          }
        </div>
        <div
          style={{verticalAlign:"top", marginTop: "7px"}}
          >
          <span style={{width:"150px", verticalAlign:"top", display: "inline-block"}}>
            <strong>Description</strong></span>

          <textarea
            name="description"
            value={description}
            onChange={this.onChange}
            style={{width: "600px", height: "60px"}}
            placeholder="Description"
          />
        </div>
        <div
          style={{marginTop:"10px"}}>
          <strong>Variables</strong>
          <div className="rTable">
            <div className="rTableRow">
              <div className="rTableHead"><strong>Name</strong></div>
              <div className="rTableHead"><strong>Value</strong></div>
              <div className="rTableHead"><strong>Description</strong></div>
            </div>
            {variables.map((v, index) =>
              <div className="rTableRow" key={index}>
                <div className="rTableCell">
                  <strong>{v.name}</strong>
                  {!v.inUse &&
                  <span>
                     - Not Used -
                    <button
                      name={`var${index}-delete`}
                      type="button"
                      className="link-button"
                      onClick={this.deleteVariable}>
                      X
                    </button>
                  </span>
                  }
                </div>
                <div className="rTableCell">
                  <textarea
                    name={`var${index}-value`}
                    value={v.value}
                    onChange={this.onChange}
                    style={{width: "500px", height: "60px"}}
                    placeholder="value"
                  />
                </div>
                <div className="rTableCell">
                  <textarea
                    name={`var${index}-description`}
                    value={v.description}
                    onChange={this.onChange}
                    style={{width: "300px", height: "50px"}}
                    placeholder="Description"
                  />
                </div>
              </div>
            )}
            <div className="rTableRow">
              <div className="rTableCell">
                <input
                  name="newVarName"
                  value={newVarName}
                  onChange={this.onChange}
                  type="text"
                  placeholder="New Variable Name"
                />
                <button
                  type="button"
                  disabled={newVarName === ''}
                  onClick={this.addVariable}
                >
                  Add
                </button>
              </div>
              <div className="rTableCell">
                <textarea
                  name="newVarValue"
                  value={newVarValue}
                  onChange={this.onChange}
                  style={{width: "500px", height: "60px"}}
                  placeholder="Value"
                />
              </div>
              <div className="rTableCell">
                <textarea
                  name="newVarDescription"
                  value={newVarDescription}
                  onChange={this.onChange}
                  style={{width: "300px", height: "50px"}}
                  placeholder="description"
                />
              </div>
            </div>
          </div>
        </div>

        <hr />
        <div>
          <span style={{width:"150px", display: "inline-block"}}><strong>Path</strong></span>
          <input
            name="path"
            value={path}
            onChange={this.onChange}
            style={{width: "300px"}}
            type="text"
            placeholder="path"
          />
        </div>
        <div
          style={{marginTop: "5px"}}
          >
          <strong>Action Details</strong>{showingDetails}
          <button
            type="button"
            className="link-button"
            onClick={this.toggleDetails}
            style={{marginLeft: "5px"}}
          >
            {showingDetails? "hide" : "show"}
          </button>
          {showingDetails && <div
            style={{border: "1px solid gray", padding: "5px"}}>
            <div id="details">
              <label>
                Request Method:
                <select
                  name="method"
                  value={method}
                  onChange={this.onChange}>
                  {REQUEST_METHOD.map(m => (
                    <option key={m} value={m}>{m}</option>
                  ))}
                </select>
              </label>
            </div>
            <div>
              <strong>Parameters</strong>
              <div className="rTable">
                <div className="rTableRow">
                  <div className="rTableHead"><strong>Name</strong></div>
                  <div className="rTableHead"><strong>Value</strong></div>
                  <div className="rTableHead">&nbsp;</div>
                </div>
                {parameters.map((param, index) =>
                  <div className="rTableRow" key={index}>
                    <div className="rTableCell">
                      <input
                        name={`param${index}-name`}
                        value={param.name}
                        onChange={this.onChange}
                        style={{width: "200px"}}
                        type="text"
                        placeholder="Parameter Name"
                      />
                    </div>
                    <div className="rTableCell">
                  <textarea
                    name={`param${index}-value`}
                    value={param.value}
                    onChange={this.onChange}
                    style={{width: "500px", height: "60px"}}
                    placeholder="Value"
                  />
                    </div>
                    <div className="rTableCell">
                      <button
                        name={`param${index}-delete`}
                        type="button"
                        className="link-button"
                        onClick={this.deleteParam}>
                        X
                      </button>
                    </div>
                  </div>
                )}
                <div className="rTableRow">
                  <div className="rTableCell">
                    <input
                      name="newParamName"
                      value={newParamName}
                      onChange={this.onChange}
                      type="text"
                      style={{width: "200px"}}
                      placeholder="New Parameter Name"
                    />
                  </div>
                  <div className="rTableCell">
                <textarea
                  name="newParamValue"
                  value={newParamValue}
                  onChange={this.onChange}
                  style={{width: "500px", height: "60px"}}
                  placeholder="Value"
                />
                  </div>
                  <div className="rTableCell">
                    <button
                      type="button"
                      disabled={newParamName === ''}
                      onClick={this.addParam}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    );
  }
}

export {INITIAL_ACTION};
export default withFirebase(ActionForm);

