import React, {Component} from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../services/Session';
import Users from '../Users';
import * as ROLES from '../../constants/roles';


class AdminPage extends Component {
  /*constructor(props) {
    super(props);
  }*/

  render() {
    return (
      <div>
        <h1>Admin</h1>
        <p>Only Admin function is administering users.</p>
        <Users authUser={this.props.authUser}/>
      </div>
    );
  }
}

const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withAuthorization(condition),
)(AdminPage);
