import React, {Component} from 'react';
import { compose } from 'recompose';

import {withFirebase} from '../../services/Firebase';
import {withAuthorization} from "../../services/Session";
import * as ROLES from "../../constants/roles";

import ProjectForm from './ProjectForm';

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      projects: [],
    };
  }

  componentDidMount() {
    this.setState({loading: true});

    this.props.firebase.projects().on('value', snapshot => {
      const projectsObject = snapshot.val() || [];

      const projectsList = Object.keys(projectsObject).map(key => ({
        ...projectsObject[key],
        pid: key
      }));

      this.setState({
        projects: projectsList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.projects().off();
  }

  render() {
    const {projects, loading} = this.state;

    return (
      <div>
        <h1>Projects</h1>
        <p>Matrix Projects - API endpoints</p>
        {loading && <div>Loading ...</div>}
        {!loading &&
        <div className="rTable">
          <div className="rTableRow">
            <div className="rTableHead"><strong>Id/Name</strong></div>
            <div className="rTableHead"><strong>URL</strong></div>
            <div className="rTableHead">&nbsp;</div>
            <div className="rTableHead">&nbsp;</div>
          </div>
          {projects.map(project => (
            <ProjectForm key={project.pid} project={project}/>
          ))}
          <ProjectForm project={null}/>
        </div>
        }
      </div>
    );
  }
}

const condition = authUser =>
  authUser && authUser.roles.includes(ROLES.ADMIN);

export default compose(
  withFirebase,
  withAuthorization(condition),
)(Projects);

