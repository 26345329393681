import React, { Component} from 'react';

import { withFirebase } from '../../services/Firebase';

const EDIT_STATUS = Object.freeze({"NEW":0, "EDITING":1, "VIEWING":2});

const INITIAL_STATE = {
  pid: 'new',
  name: '',
  url: ''
};


class ProjectForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      status: (props.project === null ? EDIT_STATUS.NEW : EDIT_STATUS.VIEWING),
      ...props.project
    };

    //bindings
    this.save = this.save.bind(this);
    this.edit = this.edit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.delete = this.delete.bind(this);
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });

  };

  save(event) {
    this.state.status === EDIT_STATUS.NEW
      ? this.props.firebase.projects().push({
          name: this.state.name,
          url: this.state.url
        })
      .then(() => {
        this.setState({ ...INITIAL_STATE});
      })
      : this.props.firebase.project(this.state.pid).update({
        name: this.state.name,
        url : this.state.url
        })
      .then(() => {
        this.setState({status: EDIT_STATUS.VIEWING});
      })

  }

  edit(event) {
    this.setState({status: EDIT_STATUS.EDITING});
  }

  cancel(event) {
    this.setState({status: EDIT_STATUS.VIEWING, ...this.props.project});
  }

  delete(event) {
    event.preventDefault();
    this.props.firebase.project(this.props.project.pid).remove();
  }
  render() {
    const { name, url, status, pid } = this.state;

    const isInvalid = name === '' || url === '';

    return (
    <div className="rTableRow" key={pid}>
      <div className="rTableCell">
        {status !== EDIT_STATUS.NEW &&
        <span>
          <strong>{pid}</strong> < br />
        </span>
        }
        {status === EDIT_STATUS.VIEWING
         ? <strong>{name}</strong>
         : <input
           name="name"
           value={name}
           onChange={this.onChange}
           type="text"
           style={{width:"300px"}}
           placeholder="Project Name"
         />
        }
      </div>
      <div className="rTableCell">
        {status === EDIT_STATUS.VIEWING
         ? <strong>{url}</strong>
         : <input
           name="url"
           value={url}
           onChange={this.onChange}
           type="text"
           style={{width:"500px"}}
           placeholder="Project Url"
         />
        }
      </div>
      <div className="rTableCell">
        {status === EDIT_STATUS.VIEWING
         ? <button
           type="button"
           onClick={this.edit}
          >
           Edit
          </button>
         : <span>
              <button
                type="button"
                disabled={isInvalid}
                onClick={this.save}
              >
                Save
              </button>
              {this.state.status === EDIT_STATUS.EDITING && <button
                 type="button"
                 onClick={this.cancel}
               >
                 Cancel
               </button>
              }
             </span>
        }
      </div>
      <div className="rTableCell">
          <span>
            {//<a href="#" onClick={this.delete}>Delete</a>
              this.state.status !== EDIT_STATUS.NEW
              ?
              <button
                type="button"
                className="link-button"
                onClick={this.delete}>
                Delete
              </button>
              : <span>&nbsp;</span>
            }
          </span>
      </div>
    </div>
    );
  }
}

export default withFirebase(ProjectForm);

