import React from 'react';

const ServerContext = React.createContext(null);

export const withServer = Component => props => (
  <ServerContext.Consumer>
    {server => <Component {...props} server={server} />}
  </ServerContext.Consumer>
);

export default ServerContext;
