import React, { Component } from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../../services/Session';
import { withFirebase } from '../../services/Firebase';
import * as ROLES from "../../constants/roles";
import * as ROUTES from "../../constants/routes";

class HomePage extends Component {

  /*
  constructor(props) {
    super(props);

  }
  */

  componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if (authUser && authUser.roles.includes(ROLES.ADMIN)) {
            this.props.history.push(ROUTES.ACTIONS);
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

  render() {
    return (
      <div>
        <h1>Matrix API Connector</h1>
        <p>Your account must be enabled for you to perform any actions.</p>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withAuthorization(condition),
)(HomePage);
