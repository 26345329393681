import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Navigation from '../Navigation';
import ProjectPage from '../Projects';
import ActionsPage from '../Actions';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AdminPage from '../Admin';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../../services/Session';

const App = () => (
  <Router>
    <div>
      <Navigation />

      <hr />

      <Route path={ROUTES.LANDING} exact component={SignInPage} />
      <Route path={ROUTES.HOME} component={HomePage} />

      <Route exact path={ROUTES.ACTIONS} component={ActionsPage} />
      <Route exact path={ROUTES.PROJECTS} component={ProjectPage} />

      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route
        path={ROUTES.PASSWORD_FORGET}
        component={PasswordForgetPage}
      />

      <Route path={ROUTES.ADMIN} component={AdminPage} />
    </div>
  </Router>

);

export default withAuthentication(App);
