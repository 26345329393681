import React, {Component} from 'react';

import {withFirebase} from '../../services/Firebase';
import AdminButton from './AdminButton';
import DeleteButton from './DeleteButton';
import * as ROLES from '../../constants/roles';

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map(key => ({
        roles: [],
        ...usersObject[key],
        uid: key,
      }));

      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h2>Users</h2>
        {loading && <div>Loading ...</div>}
        {!loading && <div className="rTable">
          <div className="rTableRow">
            <div className="rTableHead"><strong>Id/Email</strong></div>
            <div className="rTableHead"><strong>Username</strong></div>
            <div className="rTableHead"><strong>Enabled</strong></div>
            <div className="rTableHead">&nbsp;</div>
            <div className="rTableHead">&nbsp;</div>
          </div>
          {users.map(user => (
            <div className="rTableRow" key={user.uid}>
              <div className="rTableCell">
                <strong>{user.uid}</strong><br/>
                <strong>{user.email}</strong>
              </div>
              <div className="rTableCell">
                <strong>{user.username}</strong>
              </div>
              <div className="rTableCell">
                <strong>{user.roles.includes(ROLES.ADMIN) ? "Yes" : "No"}</strong>
                {user.uid !== this.props.authUser.uid && <AdminButton user={user}/>}
              </div>
              <div className="rTableCell">
                <button
                  type="button"
                  onClick={this.onSendPasswordResetEmail}
                >Send Password Reset
                </button>
              </div>
              <div className="rTableCell">
                <DeleteButton user={user}/>
              </div>
            </div>
          ))}
        </div>
        }
      </div>
    );
  }
}

export default withFirebase(UserList);
