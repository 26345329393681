

const settings = {
  defaultHeaders: [],
  defaultMethod: "GET",
};

const REQUEST_METHOD = ["GET","PUT","POST","DELETE"];

//server lasts through one invocation
class Server {

  constructor(auth) {
    this.url = auth.url;
    this.authHeader = auth.user ? window.btoa(auth.user + ':' + auth.password) : null;
  }

  /*
  action includes path, type, parameters
   */
  perform(action) {

    const method = action.method || settings.defaultMethod;
    const requestOptions = {
      method: method,
      headers: {...settings.defaultHeaders},
    };
    if (this.authHeader) {
      requestOptions.headers.Authorization = 'Basic ' + this.authHeader;
    }
    let url = this.url;
    if (action.path) {
      if (!url.endsWith('/') && !action.path.startsWith('/')) {
        url += '/';
      }
      url += this.insertVariables(action.path, action.variables);
    }

    switch (method) {
      case "GET":
      case "PUT":
        if (!url.endsWith("?")) {
          url += "?"
        }
        url += this.encodeParameters(action.parameters, action.variables);
        break;

      case "POST":
      case "DELETE":
        requestOptions.body = this.encodeParameters(action.parameters, action.variables);
        break;

      default:
        return Promise.reject(`Bad method ${method}`);
    }

    return fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          return Promise.reject(`Failed connection with status ${response.statusText}`);
        }
        return response.json();
      })
      .catch(err => {
        console.log('Fetch problem: ' + err.message);
        return Promise.reject(err.message);
      });
  }

  encodeParameters(parameters, variables) {
    let s = '';
    for (let i = 0; i < parameters.length; i++) {
      const param = parameters[i];
      s += param.name + "=" + encodeURIComponent(this.insertVariables(param.value, variables)) + "&";
    }
    return s;
  }

  insertVariables(value, variables) {
    const re = /\${([^}]+)}/g;
    let m;
    let s = '';
    let inx = 0;
    do {
      let m = re.exec(value);
      if (m) {
        const varMatch = variables.find(v => m[1] === v.name);
        s += value.substring(inx, m.index) + (varMatch ? varMatch.value : '');
        inx = re.lastIndex;
      }
    } while (m);
    s += value.substring(inx);
    return s;
  }

}


export {REQUEST_METHOD};

export default Server;
