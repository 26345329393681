import React from 'react';
import { Link } from 'react-router-dom';

import { AuthUserContext } from '../../services/Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
//import SignInPage from "../SignIn";

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <div>&nbsp;</div>
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div style={{backgroundColor:"lightblue", display: "flex"}}>
    {authUser.roles.includes(ROLES.ADMIN) && (
      <div style={{flex: 1}}>
        <Link to={ROUTES.ACTIONS}>Actions</Link>
      </div>
    )}
    {authUser.roles.includes(ROLES.ADMIN) && (
      <div style={{flex: 1}}>
        <Link to={ROUTES.PROJECTS}>Projects</Link>
      </div>
    )}
    {authUser.roles.includes(ROLES.ADMIN) && (
      <div style={{flex: 1}}>
        <Link to={ROUTES.ADMIN}>Admin</Link>
      </div>
    )}
    <div style={{flex: 1}}>
      <SignOutButton />
    </div>
  </div>
);

const NavigationNonAuth = () => (
  <ul>
    <li>
      <Link to={ROUTES.SIGN_IN}>Sign In</Link>
    </li>
  </ul>
);

export default Navigation;
